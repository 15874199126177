import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { Colors } from "~/components/ThemeVariables";

import ConnectedBlogSection from "~/components/home/BlogSection";
import HeroSection from "~/components/home/HeroSection";
import WelcomeSection from "~/components/home/WelcomeSection";
import JobSection from "~/components/home/JobSection";
import SubscribeSection from "~/components/home/SubscribeSection";
import AnimatedGraphicSection from "~/components/home/AnimatedGraphicSection";
import Layout from "~/components/Layout";
import InteroperableSection from "~/components/home/InteroperableSection";
import PartnerNetworkSection from "~/components/home/PartnerNetwork";
// import ProductSnapshots from "~/components/home/ProductSnapshots";
import TransformSection from "~/components/home/TransformSection";

import MapIcon from "~/components/home/map.inline.svg";

const MapBackground = styled.div`
    position: relative;
`;

const MapSVG = styled(MapIcon)`
    position: absolute;
    width: 100%;

    top: -100px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
`;

const BottomTextSection = styled.section`
    display: flex;
    justify-content: center;

    margin-bottom: 20px;
`;

const P = styled.p`
    color: ${Colors.WHITE};
    text-align: center;
    font-size: 24px;

    width: 90%;
    max-width: 705px;
`;

const IndexPage = (): JSX.Element => (
    <>
        <Helmet>
            <title>dClimate</title>
            <meta
                name="description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta property="og:title" content="dClimate" />
            <meta property="og:type" content="website" />

            <meta property="og:url" content="https://dclimate.net" />
            <meta
                name="og:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1200x630.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x960.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="960" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />

            <meta
                property="og:image:url"
                content="https://dclimate.net/img/meta/marketplace/1920x1080.png"
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            <meta
                property="og:image:alt"
                content="Cloud with marketplace for sharing and receiving data."
            />
            <meta property="og:site_name" content="dClimate" />

            <meta
                property="og:video"
                content="https://www.youtube.com/watch?v=nHOXUVYwWL4"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="dClimate" />
            <meta
                name="twitter:description"
                content="First Decentralized Network for Climate Data, Forecasts, and Models"
            />
            <meta
                name="twitter:image"
                content="https://dclimate.net/img/meta/twitter_summary_card.png"
            />
            <meta name="twitter:image:alt" content="get data. share data." />
        </Helmet>
        <Layout>
            <HeroSection />
            <WelcomeSection />
            <InteroperableSection />
            <PartnerNetworkSection />
            <TransformSection />
            <AnimatedGraphicSection />
            {/* <ProductSnapshots /> */}
            <ConnectedBlogSection />
            <JobSection />
            <MapBackground>
                <MapSVG />
            </MapBackground>
            <BottomTextSection>
                <P>
                    Ready to make a difference? Get involved with dClimate now
                    and help us create a better tomorrow for generations to
                    come.
                </P>
            </BottomTextSection>
            <SubscribeSection />
        </Layout>
    </>
);

export default IndexPage;
