import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import {
    breakpointIphone,
    breakpointIpad,
    breakpointSmall,
} from "~/components/styleUtils";
import { Colors, FontFamily, RgbColors } from "~/components/ThemeVariables";
import PrimaryButton from "~/components/common/PrimaryButton";

const Section = styled.section`
    padding: 120px 30px;
    display: flex;
    justify-content: center;
    font-family: ${FontFamily.SOURCE};
    color: rgba(${RgbColors.WHITE}, 0.9);

    ${breakpointIpad(`
        padding: 100px 30px;
    `)}
`;

const Content = styled.div`
    width: 100%;
    max-width: 814px;
    position: relative;
`;

const Title = styled.h4`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
    ${breakpointIpad(`
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 12px;
        text-align: center;
    `)}

    ${breakpointIphone(`
        text-align: initial;
    `)}
`;

const Form = styled.form`
    max-width: 748px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 12px;
    ${breakpointIpad(`
        max-width: none;
        grid-template-columns: 1fr;
        row-gap: 8px;
    `)}
`;

const Input = styled.input`
    padding: 10px 20px;
    font-size: 16px;
    line-height: 25px;
    border-radius: 8px;
    border: 2px solid ${Colors.ELECTRIC_BLUE};
    background: ${Colors.PRUSSIAN_BLUE};
    color: ${Colors.WHITE};

    ::placeholder {
        color: rgba(${RgbColors.WHITE}, 0.5);
    }
`;

const Butterfly = styled.div`
    position: absolute;
    top: -120px;
    right: -90px;
    width: 208px;

    ${breakpointSmall(`
        width: 106px;
        top: -50px;
        right: -10px;
    `)}

    ${breakpointIpad(`
        top: -85px;
        right: calc(50% - 180px);
    `)}

    ${breakpointIphone(`
        top: -85px;
        right: calc(50% - 150px);
    `)}
`;

const ellipsis = keyframes`
  to {
    width: 1.25em;    
  }
`;

const Loading = styled.div`
    :after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ${ellipsis} steps(4, end) 1200ms infinite;
        content: "\\2026"; /* ascii code for the ellipsis character */
        width: 0px;
    }
`;

const SubscribeSection = (): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const email = event.target.elements.email.value;

        const result = await fetch(
            "https://blog.dclimate.net/members/api/send-magic-link/",
            {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email,
                    emailType: "subscribe",
                }),
            },
        );

        try {
            await result.text();
            setLoading(false);
            setSubscribed(true);
        } catch (e) {
            setLoading(false);
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Section>
            <Content>
                <Title>Never miss an update.</Title>
                {loading && <Loading>Subscribing</Loading>}
                {!loading && error && (
                    <div>
                        Sorry an error has occured please try again later.
                    </div>
                )}
                {!loading && !error && !subscribed && (
                    <Form onSubmit={handleSubmit}>
                        <Input
                            id="email"
                            type="email"
                            placeholder="youremail@gmail.com"
                            name="EMAIL"
                        />
                        <PrimaryButton type="submit">Subscribe</PrimaryButton>
                    </Form>
                )}
                {!loading && subscribed && (
                    <div>Subscribed! Check your email!</div>
                )}
                <Butterfly>
                    <StaticImage src="./butterfly.png" alt="butterfly" />
                </Butterfly>
            </Content>
        </Section>
    );
};

export default SubscribeSection;
