import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { Colors } from "~/components/ThemeVariables";
import {
    breakpointSmall,
    breakpointIpad,
    breakpointIphone,
} from "~/components/styleUtils";

const Section = styled.section`
    padding: 0 20px;
    margin-top: 125px;
    margin-bottom: 100px;

    ${breakpointIpad(`
        margin-bottom: 50px;
    `)}
    ${breakpointIphone(`
        margin-bottom: 25px;
    `)}
`;

const H2 = styled.h2`
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: ${Colors.ELECTRIC_BLUE};

    margin-bottom: 40px;

    ${breakpointIpad(`
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 28px;
    `)}
`;

const Centering = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 80px;

    ${breakpointSmall(`
        flex-direction: column;
        align-items: center;
        row-gap: 50px;
    `)}
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    overflow: hidden;

    max-width: 561px;
`;

const H3 = styled.h3`
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    color: ${Colors.WHITE};
`;

const Pre = styled.pre`
    color: #7f86b7;
    font-size: 8px;
    white-space: pre-wrap;
`;

const TransformSection = (): JSX.Element => (
    <Section>
        <H2>Data Is Just the Base Layer</H2>
        <Centering>
            <Div>
                <H3>We turn this</H3>
                <Pre>
                    {`"documentation": {
"era5 precip": "https://apps.ecmwf.int/codes/grib/param-db?id=228",
"era5 data documentation": "https://confluence.ecmwf.int/display/CKB/ERA5%3A+data+documentation",
"era5 data access web": "https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels?tab=form",
"era5 web api documentation": "https://confluence.ecmwf.int/display/WEBAPI",
"arbol chain info": "Follow the hashes in the 'previous hash' field backward until you reach a 'previous hash' value of 'null' to read the entire history of this set. Alternatively, you can get the entire chain of hashes from the 'chain.json' file in this directory",
"arbol clustering": "In all sets besides the root set, latitudes are compiled into zip or tar archives (code should be written to handle both cases) that contain each lat/lon in the latitude"
},
"api documentation": {
"description": "ERA5 is the fifth generation ECMWF reanalysis for the global climate and weather for the past 4 to 7 decades. Currently data is available from 1950. ERA5 provides hourly estimates for a large number of atmospheric, ocean-wave and land-surface quantities. Data has been regridded to a regular lat-lon grid of 0.25 degrees for the reanalysis and 0.5 degrees for the uncertainty estimate",
"ERA5 info url": "https://confluence.ecmwf.int/display/CKB/ERA5%3A+data+documentation",
"ERA5 original data url": "https://cds.climate.copernicus.eu/api/v2",
"publisher": "Copernicus Climate Change Service (C3S)",
"full date range": [
"1979-01-01 07:00:00",
"2022-12-06 23:00:00"
]
},
"unit of measurement": "m",
"climate index": "total precipitation",
"resolution": 0.25,
"name": "era5_precip",
"time generated": "2022-12-11 19:23:09.725027",
"compression": "gzip",
"missing value": "",
"previous hash": "QmUWRzkr1msjYrgnAPxpL3Pz1ZwaWNSUFjsNPbhhNuhh9L",
"entry delimiter": ",",
"year delimiter": "\\n",
"filename decimal precision": 3,
"precision digits": 3,
"period": "hourly",
"update frequency": "daily",
"latitude range": [
-90.0,
90.0
],
"longitude range": [
0.0,
359.9`}
                </Pre>
            </Div>
            <Div>
                <H3>Into This</H3>
                <StaticImage
                    src="./marketplace.png"
                    alt="Marketplace app screenshot"
                    placeholder="blurred"
                />
            </Div>
        </Centering>
    </Section>
);

export default TransformSection;
