import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { Colors } from "~/components/ThemeVariables";
import { breakpointIpad } from "~/components/styleUtils";
import Stat300SVG from "./stat300.inline.svg";
import Stat2MSVG from "./stat2m.inline.svg";
import Stat50SVG from "./stat50.inline.svg";
import PartnerTSVG from "./partnerT.inline.svg";
import GuildOneSVG from "./GuildOne.inline.svg";

const Section = styled.section`
    display: flex;
    justify-content: center;

    margin-top: 90px;
`;

const Div = styled.div`
    width: 90%;
    max-width: 1148px;
    padding: 41px 20px;

    border-radius: 14px;
    background: radial-gradient(
        151.92% 127.02% at 15.32% 21.04%,
        rgba(165, 239, 255, 0.2) 0%,
        rgba(110, 191, 244, 0.04) 77.08%,
        rgba(70, 144, 212, 0) 100%
    );
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
`;

const H2 = styled.h2`
    font-size: 28px;
    text-align: center;
    color: ${Colors.ELECTRIC_BLUE};
`;

const Stats = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    ${breakpointIpad(`
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    `)}

    margin-top: 45px;
`;

const Stat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    row-gap: 12px;
`;

const StatP = styled.p`
    text-align: center;
    color: ${Colors.WHITE};
`;

const Partners = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 30px;
    column-gap: 50px;

    margin-top: 70px;
`;

const Partner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PartnerNetworkSection = (): JSX.Element => (
    <Section>
        <Div>
            <H2>A Taste of Our Global Data Partner Network</H2>
            <Stats>
                <Stat>
                    <Stat300SVG />
                    <StatP>unique organizations</StatP>
                </Stat>
                <Stat>
                    <Stat2MSVG />
                    <StatP>dataset downloads</StatP>
                </Stat>
                <Stat>
                    <Stat50SVG />
                    <StatP>unique vendors</StatP>
                </Stat>
            </Stats>
            <Partners>
                <Partner>
                    <StaticImage
                        src="./hyphen.png"
                        alt="Hyphen logo"
                        imgStyle={{ objectFit: "contain" }}
                        placeholder="blurred"
                    />
                </Partner>
                <Partner>
                    <StaticImage
                        src="./shamba.png"
                        alt="Shamba logo"
                        imgStyle={{ objectFit: "contain" }}
                        placeholder="blurred"
                    />
                </Partner>
                <Partner>
                    <PartnerTSVG />
                </Partner>
                <Partner>
                    <GuildOneSVG />
                </Partner>
                <Partner>
                    <StaticImage
                        src="./gem.png"
                        alt="Global Earthquake Model logo"
                        imgStyle={{ objectFit: "contain" }}
                        placeholder="blurred"
                    />
                </Partner>
            </Partners>
        </Div>
    </Section>
);

export default PartnerNetworkSection;
