import React from "react";
import styled from "styled-components";

import {
    breakpointIpad,
    breakpointSmall,
    breakpointIphone,
} from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";

import BlueFrogIcon from "./blue-frog.inline.svg";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 200px;
    padding: 0 20px;
`;

const H2 = styled.h2`
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: ${Colors.ELECTRIC_BLUE};
    max-width: 773px;

    margin-bottom: 22px;

    ${breakpointIphone(`
        font-size: 36px;
        line-height: 42px;
    `)}
`;

const P = styled.p`
    font-size: 20px;
    line-height 27.28px;
    color: ${Colors.WHITE};

    max-width: 830px;
    margin-top: 20px;

    ${breakpointIpad(`
        font-size: 18px;
    `)}

    ${breakpointIphone(`
        padding: 0 24px;
    `)}
`;

const Frog = styled.div`
    position: absolute;
    top: 1650px;
    left: -20px;
    z-index: -1;
    max-width: 309px;

    ${breakpointSmall("display: none;")}
`;

const InteroperableSection = (): JSX.Element => (
    <Section>
        <div>
            <H2>
                Interoperable Climate Data Infrastructure Uniting Climate
                Solutions
            </H2>
            <P>
                In the ever-evolving world of climate technology, seamless data
                exchange and collaboration are essential for driving impactful
                solutions. dClimate&apos;s interoperable climate data
                infrastructure is designed to break down barriers, fostering a
                cohesive ecosystem for climate data sharing and innovation.
            </P>
            <P>
                Our advanced data infrastructure enables the efficient
                integration and management of climate data from multiple
                sources, ensuring accessibility and compatibility across various
                platforms and applications. By promoting data interoperability,
                we&apos;re bridging the gap between climate tech verticals and
                providing a solid foundation for the development of
                interconnected climate solutions.
            </P>
            <P>
                Discover how dClimate&apos;s interoperable climate data
                infrastructure is unlocking new possibilities in climate action,
                fueling groundbreaking advancements, and accelerating the global
                transition to a sustainable and resilient future.
            </P>
        </div>
        <Frog>
            <BlueFrogIcon />
        </Frog>
    </Section>
);

export default InteroperableSection;
