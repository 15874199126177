import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { breakpointIpad } from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";
import PostGrid from "~/components/common/PostGrid";

const Section = styled.section`
    padding: 150px 30px;
    display: grid;
    place-items: center;

    ${breakpointIpad(`
        padding: 60px 30px;
    `)}
`;

const Title = styled.h2`
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 40px;
    color: ${Colors.ELECTRIC_BLUE};

    ${breakpointIpad(`
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 28px;
    `)}
`;

const ConnectedBlogSection = (): JSX.Element => {
    const result = useStaticQuery(graphql`
        query {
            allGhostPost(
                limit: 3
                sort: { fields: published_at, order: DESC }
            ) {
                edges {
                    node {
                        url
                        title
                        excerpt
                        feature_image
                        feature_image_alt
                    }
                }
            }
        }
    `);

    const posts = useMemo(() => {
        if (!result) return undefined;
        // Handle errors
        if (result.errors) {
            return [];
        }

        if (!result.allGhostPost) {
            return [];
        }
        const items = result.allGhostPost.edges;
        return items.map(
            ({
                node: {
                    url,
                    title,
                    excerpt,
                    feature_image: featureImage,
                    feature_image_alt: featureImageAlt,
                },
            }) => ({
                link: url,
                subtitle: excerpt,
                title,
                image: featureImage,
                imageAlt: featureImageAlt,
            }),
        );
    }, [result]);

    return (
        <Section>
            <Title>From Our Blog</Title>
            <PostGrid posts={posts} />
        </Section>
    );
};

export default ConnectedBlogSection;
