import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import {
    breakpointIpad,
    breakpointIphone,
    breakpointSmall,
} from "~/components/styleUtils";
import { Colors } from "~/components/ThemeVariables";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 120px 0px 30px;
    color: ${Colors.WHITE};
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 70px;
    max-width: 1067px;
    margin-bottom: 15px;

    ${breakpointSmall(`
        font-size: 60px;
    `)}

    ${breakpointIpad(`
        font-size: 40px;
        line-height: 46px;
    `)}

    ${breakpointIphone(`
        font-size: 32px;
        line-height: 36px;
    `)}
`;

const Description = styled.p`
    font-size: 20px;
    line-height: 27px;
    max-width: 740px;

    ${breakpointSmall(`
        font-size: 18px;
        line-height: 25px;
        max-width: 550px;
    `)}
`;

const Turbine = styled.div`
    position: absolute;
    top: 300px;
    right: 0;
    max-width: 425px;
    // Negative z-index to put it behind the big heading text
    z-index: -1;

    ${breakpointIpad(`
        max-width: 210px;
        top: -80px;
    `)}

    ${breakpointSmall(`
        top: -40px; 
        max-width: 325px;
    `)}
`;

const HeroSection = (): JSX.Element => (
    <Section>
        <div>
            <Title>
                Unlocking the Power of Climate Data for a Sustainable Future
            </Title>
            <Description>
                dClimate is a decentralized climate data infrastructure network
                transforming the way climate tech applications are brought to
                life to power a resilient future.
            </Description>
        </div>
        <Turbine>
            <StaticImage
                src="./turbine.png"
                alt="Turbine Image"
                placeholder="blurred"
            />
        </Turbine>
    </Section>
);

export default HeroSection;
