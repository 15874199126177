import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Colors } from "~/components/ThemeVariables";
import ExternalLink from "~/components/common/ExternalLink";
import { breakpointIpad } from "~/components/styleUtils";

const H1 = styled.h1`
    display: block;
    text-align: center;

    font-weight: 700;
    font-size: 37px;
    color: ${Colors.ELECTRIC_BLUE};

    margin-bottom: 60px;
`;

const TeamTitle = styled.h2`
    font-weight: 600;
    font-size: 28px;

    ${breakpointIpad(`
        text-align: center;
    `)}

    color: ${Colors.WHITE};

    margin-bottom: 20px;
`;

interface Role {
    title: string;
    location: string;
    link: string;
}

const JobCards = styled.div`
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 20px;

    ${breakpointIpad(`
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    `)}

    margin-bottom: 80px;
`;

const JobCardContainer = styled.button`
    height: 130px;
    width: 100%;
    padding: 25px 37px;
    cursor: pointer;

    ${breakpointIpad(`
        min-width: 330px;
        max-width: 430px;
        width: 90vw;
    `)}

    background: linear-gradient(109.8deg, #2e5274 0%, #053152 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    border: none;
`;

const JobCardTitle = styled.p`
    font-weight: 500;
    font-size: 16px;
    text-align: left;

    color: ${Colors.WHITE};

    margin-bottom: 4px;
`;

const JobLocation = styled.div`
    display: flex;
    column-gap: 5px;
`;

const JobLocationPin = () => (
    <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.00728 0.400146C3.85528 0.400146 0.483276 3.77215 0.483276 7.92415C0.483276 12.0761 8.00728 19.6001 8.00728 19.6001C8.00728 19.6001 15.5169 12.0761 15.5169 7.92415C15.5169 3.77215 12.1593 0.400146 8.00728 0.400146ZM8.00728 11.9297C5.78488 11.9297 4.00168 10.1322 4.00168 7.92415C4.00168 5.71615 5.7873 3.91855 8.00728 3.91855C10.2153 3.91855 12.0009 5.71613 12.0009 7.92415C12.0009 10.1321 10.2153 11.9297 8.00728 11.9297Z"
            fill="white"
        />
    </svg>
);

const JobCardLocation = styled.p`
    font-weight: 300;
    font-size: 15px;

    color: ${Colors.WHITE};
`;

const JobCard = ({ title, location, link }: Role) => (
    <ExternalLink to={link}>
        <JobCardContainer>
            <JobCardTitle>{title}</JobCardTitle>
            <JobLocation>
                <JobLocationPin />
                <JobCardLocation>{location}</JobCardLocation>
            </JobLocation>
        </JobCardContainer>
    </ExternalLink>
);

const TeamDiv = styled.div`
    width: 100%;
    max-width: 900px;
`;

const Team = ({ teamTitle, roles }) => (
    <TeamDiv>
        <TeamTitle>
            {teamTitle.slice(-4).toLocaleLowerCase().localeCompare("Team") !== 0
                ? `${teamTitle} Team`
                : teamTitle}
        </TeamTitle>
        <JobCards>
            {(roles === undefined ? [] : roles).map(
                ({ title, location, link }) => (
                    <JobCard
                        // The key is the link since there may be multiple jobs with the same title
                        key={link}
                        title={title}
                        location={location}
                        link={link}
                    />
                ),
            )}
        </JobCards>
    </TeamDiv>
);

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 20px;
    margin: 100px 0;
`;

const JobSection = (): JSX.Element => {
    const [ashbyData, setAshbyData] = useState(undefined);

    useEffect(() => {
        const fetchOptions = {
            method: "GET",
            headers: {
                accept: "application/json",
                "content-type": "application/json",
            },
        };
        fetch(
            "https://landing-page-functions.netlify.app/.netlify/functions/careers",
            fetchOptions,
        )
            .then((response) => response.json())
            .then((response) => setAshbyData(response.data))
            // Ignore the browser console error statement since, as landing-page is a static website, the only way to print errors is to the browser console
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error));
    }, []);

    // If either the Netlify function isn't working or there are no jobs available then just don't display
    if (ashbyData === undefined || ashbyData.length === 0) {
        return <></>;
    }

    // Make list of distinct team names
    const teamNames = Object.keys(
        ashbyData.reduce((acc, job) => {
            acc[job.teamName] = 1;
            return acc;
        }, {}),
    );

    // grab all distinct team names
    const teams = {};
    teamNames.forEach((teamName) => {
        teams[teamName] = {
            teamName,
            jobs: [],
        };
    });

    // put all jobs into their team
    ashbyData.forEach((job) => {
        teams[job.teamName].jobs.push({
            title: job.title,
            location: job.locationName,
            link: job.externalLink,
        });
    });

    // convert to array for easier processing in React later
    const teamsAsArray = [];
    Object.values(teams).forEach((t) => teamsAsArray.push(t));

    return (
        <Section>
            <H1>We&apos;re Hiring</H1>
            {teamsAsArray.map((team) => (
                <Team
                    key={team.teamName}
                    teamTitle={team.teamName}
                    roles={team.jobs}
                />
            ))}
        </Section>
    );
};

export default JobSection;
