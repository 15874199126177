import React, { FC } from "react";
import styled from "styled-components";

import ExternalLink from "~/components/common/ExternalLink";
import { Colors, RgbColors, Gradient } from "~/components/ThemeVariables";
import { breakpointIpad, transition } from "~/components/styleUtils";

const Container = styled.div`
    height: 100%;

    a {
        display: block;
        height: 100%;
        color: inherit;
        text-decoration: none;
        :hover {
            span {
                color: ${Colors.WHITE};
            }
        }
    }
`;

const Border = styled.div`
    height: 100%;
    border-radius: 10px;
    padding: 2px;
    background: ${Gradient.RADIAL_BG_BORDER};
`;

const Post = styled.article`
    border-radius: 10px;
    display: grid;
    grid-template-rows: 218px 1fr;
    height: 100%;
    background: ${Gradient.LINEAR};
`;

const Img = styled.img`
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Content = styled.div`
    position: relative;
    padding: 20px 22px 50px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 0px 0px 10px 10px;
    background: ${Gradient.RADIAL};

    h4 {
        margin-bottom: 9px;
        font-size: 18px;
        line-height: 25px;
        color: ${Colors.WHITE};
    }

    p {
        color: rgba(${RgbColors.WHITE}, 0.8);
    }
`;

const Read = styled.span`
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${Colors.ELECTRIC_BLUE};
    ${transition()}
`;

export interface PostProps {
    title: string;
    image: string;
    imageAlt: string;
    link: string;
    subtitle: string;
}

const DynamicPost: FC<PostProps> = ({
    title,
    image,
    imageAlt,
    link,
    subtitle = "",
}): JSX.Element => (
    <Container>
        <ExternalLink to={link}>
            <Border>
                <Post>
                    <div>
                        <Img src={image} alt={imageAlt} loading="lazy" />
                    </div>
                    <Content>
                        <h4>{title}</h4>
                        <p>{subtitle}</p>
                        <Read>Read Story</Read>
                    </Content>
                </Post>
            </Border>
        </ExternalLink>
    </Container>
);

const Grid = styled.div`
    width: 100%;
    max-width: 1248px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(404px, auto);
    column-gap: 40px;
    row-gap: 40px;

    ${breakpointIpad(`
        grid-template-columns: 1fr;
        column-gap: 20px;
        row-gap: 20px;
    `)}
`;

interface PostGridProps {
    posts: PostProps[];
}

const PostGrid: FC<PostGridProps> = ({ posts = [] }): JSX.Element => {
    if (!posts.length) return null;

    return (
        <Grid>
            {posts.map((p) => (
                <DynamicPost
                    key={p.link}
                    title={p.title}
                    image={p.image}
                    imageAlt={p.imageAlt}
                    link={p.link}
                    subtitle={p?.subtitle}
                />
            ))}
        </Grid>
    );
};

export default PostGrid;
