import React from "react";
import styled from "styled-components";

import { Colors } from "~/components/ThemeVariables";
import { breakpointIpad, breakpointIphone } from "~/components/styleUtils";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 45px;
    padding: 0 20px;
`;

const Div = styled.div`
    width: 90%;
    max-width: 1070px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 54px;
    row-gap: 20px;

    border-radius: 14px;
    background: radial-gradient(
        151.92% 127.02% at 15.32% 21.04%,
        rgba(165, 239, 255, 0.2) 0%,
        rgba(110, 191, 244, 0.04) 77.08%,
        rgba(70, 144, 212, 0) 100%
    );
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);

    ${breakpointIphone(`
        padding: 28px;
    `)}
`;

const P = styled.p`
    font-size: 20px;
    line-height 27.28px;
    color: ${Colors.WHITE};

    max-width: 830px;

    ${breakpointIpad(`
        font-size: 18px;
    `)}
`;

const Video = styled.iframe`
    width: 100%;
    max-width: 990px;
    aspect-ratio: 990/551;

    border: none;
    margin-top: 80px;
`;

const WelcomeSection = (): JSX.Element => (
    <Section>
        <Div>
            <P>
                dClimate is forging a new path in the world of climate
                technology, harnessing the power of our comprehensive climate
                data and robust infrastructure to create transformative
                solutions for a sustainable future. We&apos;re dedicated to
                empowering businesses, governments, and innovators to measure,
                monitor, and mitigate climate risk, bridging the gap between
                climate data and actionable insights.
            </P>
            <P>
                We use open data tools and emerging technologies like AI and
                blockchain to address pressing climate issues, sparking
                innovation and driving verifiable climate action. Join us, as we
                shape a resilient and sustainable world for the future.
            </P>
        </Div>
        <Video
            src="https://www.youtube.com/embed/nHOXUVYwWL4?modestbranding=1&rel=0"
            title="dClimate explanation"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </Section>
);

export default WelcomeSection;
