import React, { useState } from "react";
import styled from "styled-components";

import Tier1SVG from "./tier1.inline.svg";
import T1headingSkewedSVG from "./t1heading-skewed.inline.svg";
import T1headingSVG from "./t1heading.inline.svg";
import T1paragraphSVG from "./t1paragraph.inline.svg";

import Tier2SVG from "./tier2.inline.svg";
import T2headingSkewedSVG from "./t2heading-skewed.inline.svg";
import T2headingSVG from "./t2heading.inline.svg";
import T2paragraphSVG from "./t2paragraph.inline.svg";

import Tier3SVG from "./tier3.inline.svg";
import T3headingSkewedSVG from "./t3heading-skewed.inline.svg";
import T3headingSVG from "./t3heading.inline.svg";
import T3paragraphSVG from "./t3paragraph.inline.svg";

import { breakpointIpad } from "~/components/styleUtils";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AnimatedGraphic = () => {
    const [viewing, setViewing] = useState({
        isViewingATier: false,
        tierBeingViewed: undefined,
    });

    const startViewingTier = (tierBeingViewed: number) => {
        if (!viewing.isViewingATier) {
            setViewing({
                isViewingATier: true,
                tierBeingViewed,
            });
        }
    };

    const stopViewingATier = () => {
        if (viewing.isViewingATier) {
            setViewing({
                isViewingATier: false,
                tierBeingViewed: undefined,
            });
        }
    };

    const displayT1SVG =
        !viewing.isViewingATier ||
        (viewing.isViewingATier && viewing.tierBeingViewed === 1);
    const displayT2SVG =
        !viewing.isViewingATier ||
        (viewing.isViewingATier && viewing.tierBeingViewed === 2);
    const displayT3SVG =
        !viewing.isViewingATier ||
        (viewing.isViewingATier && viewing.tierBeingViewed === 3);

    const displayT1Text =
        viewing.isViewingATier && viewing.tierBeingViewed === 1;
    const displayT2Text =
        viewing.isViewingATier && viewing.tierBeingViewed === 2;
    const displayT3Text =
        viewing.isViewingATier && viewing.tierBeingViewed === 3;

    return (
        <>
            <g transform="translate(200,0)">
                <g
                    id="t1svg"
                    transform="translate(-80, 770)"
                    onMouseOver={() => startViewingTier(1)}
                    onMouseOut={() => stopViewingATier()}
                    opacity={displayT1SVG ? "default" : "0.1"}
                >
                    <Tier1SVG />
                </g>
                <g
                    id="t2svg"
                    transform="translate(-70,400)"
                    onMouseOver={() => startViewingTier(2)}
                    onMouseOut={() => stopViewingATier()}
                    opacity={displayT2SVG ? "default" : "0.1"}
                >
                    <Tier2SVG />
                </g>
                <g
                    id="t3svg"
                    onMouseOver={() => startViewingTier(3)}
                    onMouseOut={() => stopViewingATier()}
                    opacity={displayT3SVG ? "default" : "0.1"}
                >
                    <Tier3SVG />
                </g>
            </g>
            <g
                id="t1hskewed"
                transform="translate(80, 900)"
                opacity={viewing.isViewingATier ? "0" : "1"}
            >
                <T1headingSkewedSVG />
            </g>
            <g
                id="t2hskewed"
                transform="translate(80, 550)"
                opacity={viewing.isViewingATier ? "0" : "1"}
            >
                <T2headingSkewedSVG />
            </g>
            <g
                id="t3hskewed"
                transform="translate(80, 250)"
                opacity={viewing.isViewingATier ? "0" : "1"}
            >
                <T3headingSkewedSVG />
            </g>
            <g
                id="t1text"
                transform="translate(550, 600)"
                // display={displayT1Text ? "default" : "none"}
                opacity={displayT1Text ? "1" : "0"}
            >
                <T1headingSVG />
                <g transform="translate(-150, 75)">
                    <T1paragraphSVG />
                </g>
            </g>
            <g
                id="t2text"
                transform="translate(500, 1050)"
                // display={displayT2Text ? "default" : "none"}
                opacity={displayT2Text ? "1" : "0"}
            >
                <T2headingSVG />
                <g transform="translate(-100, 75)">
                    <T2paragraphSVG />
                </g>
            </g>
            <g
                id="t3text"
                transform="translate(525, 650)"
                // display={displayT3Text ? "default" : "none"}
                opacity={displayT3Text ? "1" : "0"}
            >
                <T3headingSVG />
                <g transform="translate(-125, 63)">
                    <T3paragraphSVG />
                </g>
            </g>
        </>
    );
};

const TouchscreenText = styled.section`
    display: none;

    ${breakpointIpad(`
        display: flex;
        flex-direction: column;
        align-items: center;
    `)}
`;

const Diagram = styled.svg`
    width: 90%;
    max-width: 900px;

    #t1svg,
    #t2svg,
    #t3svg {
        cursor: pointer;
    }

    #t1svg,
    #t2svg,
    #t3svg,
    #t1hskewed,
    #t2hskewed,
    #t3hskewed,
    #t1text,
    #t2text,
    #t3text {
        transition: all 0.4s ease-in;
    }
`;

const H2 = styled.h2`
    text-align: center;
    font-size: 40px;
    font-weight: 700;
`;

const H2Products = styled(H2)`
    color: #bc80ff;
`;

const H2Analytics = styled(H2)`
    color: #a0a0ff;
`;

const H2Data = styled(H2)`
    color: #6bd4e7;
`;

const P = styled.p`
    width: 90%;
    max-width: 560px;

    font-size: 18px;
    font-weight: 300;
    color: #fff;

    margin-top: 12px;
    margin-bottom: 70px;
`;

const AnimatedGraphicSection = (): JSX.Element => (
    <Section>
        <Diagram viewBox="50 0 1175 1500">
            <AnimatedGraphic />
        </Diagram>
        <TouchscreenText>
            <H2Products>Climate Products</H2Products>
            <P>
                On top of the first two layers, enterprises can go even deeper,
                and begin to use data, analytics, and models to build advanced
                financial infrastructure and products like parametric insurance
                and applications like dClimate’s WorkSight app for the
                construction industry.
            </P>
            <H2Analytics>Analytics & Forecasts</H2Analytics>
            <P>
                Analytics, forecasts, and advanced models enable businesses,
                communities, and governments to leverage raw, historical data to
                better understand what is likely to happen in the future so they
                can proactively plan, prepare, and build efficient and resilient
                operations.
            </P>
            <H2Data>Climate Data</H2Data>
            <P>
                The data layer refers to raw data on important climate variables
                such as rainfall, windspeed, carbon dioxide, soil moisture, and
                more. Standardized, ready for institutional use data is needed
                to build advanced tooling, products, and forecasts.
            </P>
        </TouchscreenText>
    </Section>
);

export default AnimatedGraphicSection;
